import { graphql, useStaticQuery } from 'gatsby';

export const useProcessPageData = () => {
  const data = useStaticQuery(graphql`
    query {
      processPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
      }
    }
  `);

  return data.processPageDataJson;
};
