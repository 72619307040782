import React from 'react';

// External Components
import { Section, Heading, Paragraph, Box } from '@thepuzzlers/pieces';
import { convertApiHtmlText } from 'graphqlHooks/helper';
import { GridSpacer } from 'components';

export const StepItemSection = ({
  data: { description, headline, list },
  id,
  notWithTopSpacer = false,
  notWithBottomSpacer = false,
  HeadlineDecoration = null,
  ...props
}) => {
  return (
    <Section id={id} {...props}>
      {!notWithTopSpacer && <TopSpacer />}
      <HeadlineWithDecoration
        headline={headline}
        HeadlineDecoration={HeadlineDecoration}
      />
      <HeadlineSpacer />
      <List list={list} />
      <Description description={description} />
      {!notWithBottomSpacer && <BottomSpacer />}
    </Section>
  );
};

const HeadlineWithDecoration = ({ headline, HeadlineDecoration }) => {
  return (
    // Additional box is needed to add the decoration, because we cannot set children inside element that use dangerouslySetInnerHTML
    <Box
      className="__headline-with-decoration"
      sx={{
        gridColumn: ['1/13', null, '1/25', null, '1/25', '3/ span 20'],
        position: 'relative'
      }}>
      <Heading
        as="h2"
        dangerouslySetInnerHTML={{ __html: convertApiHtmlText(headline.html) }}
        className="__headline"
        sx={{
          fontFamily: 'primary.normal',
          '& > span': {
            fontFamily: 'primary.bold'
          },
          lineHeight: 1.35,
          fontSize: ['3.2rem', null, '4rem', null, '4.8rem', '4.8rem']
        }}
      />
      {!!HeadlineDecoration && <HeadlineDecoration />}
    </Box>
  );
};

const List = ({ list }) => {
  return (
    <Box
      as="ul"
      className="__list"
      sx={{
        gridColumn: [
          '1/13',
          null,
          '1/ span 10',
          null,
          '1/ span 11',
          '3/ span 9'
        ],
        pl: ['2.4rem', null, '2.6rem', null, '2.6rem', '2.8rem']
      }}>
      {list.map((data, index) => {
        return <ListItem data={data} key={index} />;
      })}
    </Box>
  );
};

const ListItem = ({ data: { text } }) => {
  return (
    <Paragraph
      as="li"
      sx={{
        fontFamily: 'body.boldItalic',
        fontSize: ['1.7rem', null, '1.7rem', null, '1.7rem', '1.7rem'],
        lineHeight: 1.75,
        mt: ['3rem', null, 0, null, 0, 0],
        ':not(:first-of-type)': {
          mt: ['2rem', null, '2.4rem', null, '2.4rem', '2.4rem']
        },
        position: 'relative'
      }}>
      <ListBullet />
      <Box
        as="span"
        sx={{
          position: 'relative'
        }}>
        {text}
      </Box>
    </Paragraph>
  );
};

const ListBullet = () => {
  return (
    <Box
      className="__list-bullet"
      sx={{
        width: ['1.4rem', null, '1.4rem', null, '1.6rem', '1.7rem'],
        height: ['1.4rem', null, '1.4rem', null, '1.6rem', '1.7rem'],
        border: '1px solid',
        borderColor: 'primary',
        position: 'absolute',
        left: ['-2.2rem', null, '-2.3rem', null, '-2.4rem', '-2.7rem'],
        top: ['0.7rem', null, '0.7rem', null, '0.5rem', '0.5rem']
      }}
    />
  );
};

const Description = ({ description }) => {
  return (
    <Paragraph
      dangerouslySetInnerHTML={{ __html: convertApiHtmlText(description.html) }}
      className="__description"
      sx={{
        fontFamily: 'body.normal',
        '& > span': {
          fontFamily: 'body.boldItalic'
        },
        lineHeight: 1.75,
        fontSize: ['1.6rem', null, '1.7rem', null, '1.7rem', '1.7rem'],
        gridColumn: ['1/13', null, '13/25', null, '14/25', '13/ span 9'],
        mt: ['3rem', null, 0, null, 0, 0]
      }}
    />
  );
};

const TopSpacer = () => {
  return (
    <GridSpacer height={['12rem', null, '14rem', null, '15rem', '15rem']} />
  );
};

const HeadlineSpacer = () => {
  return (
    <GridSpacer
      height={[null, null, '4rem', null, '8rem', '8rem']}
      sx={{
        display: ['none', null, 'block', null, 'block', 'block']
      }}
    />
  );
};

const BottomSpacer = () => {
  return (
    <GridSpacer height={['12rem', null, '14rem', null, '15rem', '15rem']} />
  );
};
