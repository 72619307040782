import React from 'react';
import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';
import { Helmet } from 'react-helmet';
import { Box } from '@thepuzzlers/pieces';

// Local Components
import {
  Header,
  useProcessPageData,
  Description,
  Steps
} from 'sections/process';
import { Spacer, Suitability } from 'components';

// Assets
import thumbnail from 'assets/seo/thumbnail-process.jpg';

// Hooks
import { useKisProcessPageData } from 'graphqlHooks';

const Process = () => {
  const { seo } = useProcessPageData();

  const {
    header: cmsHeader,
    description: cmsDescription,
    steps: cmsSteps
  } = useKisProcessPageData();

  return (
    <>
      <SEO {...seo} />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:image" content={thumbnail} />

        {/* Twitter */}
        <meta property="twitter:url" content={seo.url} />
        <meta property="twitter:image" content={thumbnail} />
      </Helmet>
      <Header data={cmsHeader} />
      <Spacer height={['13.3rem', null, '10.5rem', null, '12.6rem', '0rem']} />
      <Box
        as="main"
        sx={{
          overflow: 'hidden'
        }}>
        <Description data={cmsDescription} />
        <Spacer height={['16rem', null, '20rem', null, '20rem', '24.9rem']} />
        <Steps data={cmsSteps} />
        <Spacer height={['20rem', null, '28rem', null, '26rem', '29.8rem']} />
        <Suitability />
        <Spacer height={['30rem', null, '28rem', null, '26rem', '30rem']} />
      </Box>
    </>
  );
};

export default injectIntl(Process);
