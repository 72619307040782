import React from 'react';

// External Components
import { Section, Paragraph } from '@thepuzzlers/pieces';
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Description = ({ data: { description } }) => {
  return (
    <Section id="process-page__description">
      <TextBlock description={description} />
    </Section>
  );
};

const TextBlock = ({ description }) => {
  return (
    <Paragraph
      dangerouslySetInnerHTML={{ __html: convertApiHtmlText(description.html) }}
      className="__text-block"
      sx={{
        fontFamily: 'primary.normal',
        '& > span': {
          fontFamily: 'primary.bold'
        },
        lineHeight: 1.5,
        fontSize: ['2.4rem', null, '2.8rem', null, '3.2rem', '3.2rem'],
        gridColumn: [
          '1/13',
          null,
          '3/ span 20',
          null,
          '5/ span 16',
          '5/ span 16'
        ]
      }}
    />
  );
};
