import React from 'react';

// External Components

import { Section } from '@thepuzzlers/pieces';

// Self Components
import { StepItemSection } from './components';
import { HorizontalDivider, LineThree, LineTwo } from 'components';

export const Steps = ({ data: { step_list } }) => {
  return (
    <>
      <StepItemSection
        id="process-step-one"
        data={step_list[0].data}
        notWithTopSpacer
        HeadlineDecoration={StepOneHeadlineDecoration}
      />
      <StepSectionDivider />
      <StepItemSection
        id="process-step-two"
        data={step_list[1].data}
        HeadlineDecoration={StepTwoHeadlineDecoration}
      />
      <StepSectionDivider />
      <StepItemSection
        id="process-step-three"
        data={step_list[2].data}
        notWithBottomSpacer
      />
    </>
  );
};

const StepSectionDivider = () => {
  return (
    <Section>
      <HorizontalDivider
        sx={{
          gridColumn: ['1/13', null, '1/25', null, '1/25', '3/ span 20']
        }}
      />
    </Section>
  );
};

const StepOneHeadlineDecoration = () => {
  return (
    <LineTwo
      sx={{
        position: 'absolute',
        width: ['21.7rem', null, '21.7rem', null, '28.7rem', '28.7rem'],
        bottom: ['1.9rem', null, '-2.5rem', null, '-2.5rem', '-2.6rem'],
        right: ['-11.4rem', null, '20.1rem', null, '37.3rem', '46.9rem']
      }}
    />
  );
};

const StepTwoHeadlineDecoration = () => {
  return (
    <LineThree
      sx={{
        position: 'absolute',
        width: ['25.8rem', null, '25.8rem', null, '28.6rem', '30rem'],
        top: ['-3.4rem', null, '-4.5rem', null, '-4.7rem', '-5.3rem'],
        left: ['-7.6rem', null, '33rem', null, '46.2rem', '45.9rem']
      }}
    />
  );
};
